<template>
  <calendesk-tabs v-show="getStatsLoaded" :items="getItems" :route="route" />
</template>

<script>
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import path from "@/router/path";
import CalendeskTabs from "@/components/CTabs/CalendeskTabs.vue";
import roleActions from "@/calendesk/mixins/roleActions";
import sharedTabsActions from "@/calendesk/mixins/sharedTabsActions";

export default {
  name: "SalesWrapper",
  components: { CalendeskTabs },
  mixins: [sharedActions, roleActions, sharedTabsActions],
  data() {
    return {
      route: path.notifications.main,
    };
  },
  computed: {
    getItems() {
      const items = [];

      if (this.loggedUserCanEditPackages) {
        items.push({
          id: this.availableTabs.notifications.settings,
          title: this.$trans("settings"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.notifications.settings },
          },
          content: () =>
            import(
              "@/views/dashboard/pages/Notifications/NotificationsSettings"
            ),
        });
      }

      if (this.loggedUserCanEditPackages) {
        items.push({
          id: this.availableTabs.notifications.templates,
          title: this.$trans("notification_templates_list"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.notifications.templates },
          },
          content: () =>
            import(
              "@/views/dashboard/pages/Notifications/NotificationTemplates/NotificationTemplatesList"
            ),
        });
      }

      if (this.loggedUserCanShowNewsletter) {
        items.push({
          id: this.availableTabs.notifications.newsletter,
          title: this.$trans("user_newsletter"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.notifications.newsletter },
          },
          content: () =>
            import(
              "@/views/dashboard/pages/Notifications/Newsletter/Newsletters"
            ),
        });
      }

      if (this.loggedUserCanShowNotifications) {
        items.push({
          id: this.availableTabs.notifications.activity,
          title: this.$trans("activity"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.notifications.activity },
          },
          content: () =>
            import(
              "@/views/dashboard/pages/Notifications/CustomerNotifications/CustomerNotifications"
            ),
        });
      }

      return items;
    },
  },
};
</script>
